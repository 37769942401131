<script>
import appConfig from '@src/app.config'
import scrollEvent from '@utils/scroll-animation.js'
import PageHeader from "@components/page-header.vue";
import ModalContainer from "@components/modal-container.vue";
import Contacts from "@components/contacts.vue";
import MainScreenPagesSection from "@components/sections/main-screen-pages.vue";
import DataPresentationPagesSection from "@components/sections/data-presentation-pages.vue";
import IndustriesSection from "@components/sections/industries.vue";
import ServicesSection from "@components/sections/services.vue";
import HowItWorksSection from "@components/sections/how-it-works.vue";
import InvestmentSection from "@components/sections/investment.vue";
import JustTrySection from "@components/sections/just-try.vue";
import ArticlesSection from "@components/sections/articles.vue";
import QuestionSection from "@components/sections/question.vue";
import FaqSection from "@components/sections/faq.vue";
import DocumentsSection from "@components/sections/documents.vue";
import FullSiteFooter from '@components/full-site-footer.vue'
import CookiesWarning from '@components/elements/cookies-warning.vue'
import Layout from '@layouts/main.vue'

Number.prototype.lead0 = function (n) {
    var nz = "" + this;
    while (nz.length < n) {
        nz = "0" + nz;
    }
    return nz;
};

export default {
    name: 'Business',
    page: {
        meta: [{name: 'description', content: appConfig.description}],
    },
    components: {
        PageHeader,
        ModalContainer,
        Contacts,
        MainScreenPagesSection,
        DataPresentationPagesSection,
        IndustriesSection,
        ServicesSection,
        HowItWorksSection,
        InvestmentSection,
        JustTrySection,
        ArticlesSection,
        QuestionSection,
        FaqSection,
        DocumentsSection,
        FullSiteFooter,
        CookiesWarning,
        Layout,
    },
    data() {
        return {
            topTitle: 'Геоэффект бизнес',
            topText: 'Выбирайте лучшие места для&nbsp;открытия новых точек продаж и&nbsp;развития сети. Сравнивайте и&nbsp;анализируйте трафик и&nbsp;конкурентов',
            slides: [
                '/assets/images/carousels/geo/1.webp',
                '/assets/images/carousels/geo/2.webp',
                '/assets/images/carousels/geo/3.webp',
                '/assets/images/carousels/geo/4.webp',
                '/assets/images/carousels/geo/5.webp',
            ],
            dataPresentationBlocks: [
                '<span>Поиск новых точек</span>',
                '<span>Трафик</span>',
                '<span>Рейтинг локаций</span>',
                '<span>Анализ</span>',
                '<span>Расходы</span>',
            ],
            dataPresentationBlocksAdditional: [
                'Оптимальные локации для открытия бизнеса',
                'Места концентрации целевой аудитории',
                'Индекс торговых точек для управления логистикой и товарооборотом',
                'Конкурентный анализ и исследования',
                'Объемы трат и средние чеки по категориям трат',
            ],
            justTryBlockData: {
                serviceName: 'Оцените возможности Геоэффект бизнес',
                serviceSubtitle: 'с помощью демоверсии',
                imgNum: 3,
                buttonText: 'Перейти к демо'
            },
            services: [
                {
                    title: 'Цифровой житель',
                    description: 'Оцените спрос на инфраструктуру с помощью данных о профиле, численности и плотности населения',
                    background: 'tab-map',
                    link: 'citizen',
                },
                {
                    title: 'Цифровой туризм',
                    description: 'Повышайте потенциал туристической отрасли, анализируя турпоток в регионе',
                    background: 'tab-tourism',
                    link: 'tourist',
                },
                {
                    title: 'Посещаемость',
                    description: 'Анализ событий и мероприятий, исследуйте посещаемость объектов и территорий, отслеживайте динамику изменений',
                    background: 'tab-visiting',
                    link: 'business',
                },
            ],
            mobileNavigationBg: false,
            headerLinks: [
                {
                    text: 'Цифровой житель',
                    external: false,
                    link: 'citizen',
                    analyticsLabel: 'cifrovoi_zhitel',
                },
                {
                    text: 'Цифровой туризм',
                    external: false,
                    link: 'tourist',
                    analyticsLabel: 'cifrovoi_turizm',
                },
                {
                    text: 'Посещаемость',
                    external: false,
                    link: 'visiting',
                    analyticsLabel: 'poseschaemost',
                },
                {
                    text: 'Геоэффект бизнес',
                    external: false,
                    link: 'business',
                    analyticsLabel: 'geoeffekt_biznes',
                },
                {
                    text: 'Документация',
                    external: false,
                    link: null,
                    analyticsLabel: 'dokumentaciya',
                },
            ],
        }
    },
    created() {
        window.addEventListener("scroll", scrollEvent);
    },
}
</script>

<template>
    <Layout class="landing-page">
        <PageHeader
            :links="headerLinks"
            :logo-link="false"
            :show-login-button="true"
        />

        <ModalContainer />

        <Contacts />

        <div id="main-page" class="city-map">
            <MainScreenPagesSection
                :title="topTitle"
                :text="topText"
                :button-text="'Попробовать демо'"
                :button-link-to-geoeffect="true"
                :slides="slides"
            />

            <DataPresentationPagesSection
                :blocks="dataPresentationBlocks"
                :additional-text="dataPresentationBlocksAdditional"
                :block-minimal-width-tablet="212"
                :small-title="true"
            />
        </div>

        <IndustriesSection />

        <ServicesSection :services="services" />

        <HowItWorksSection />

<!--        <InvestmentSection />-->

        <JustTrySection
            :service-name="justTryBlockData.serviceName"
            :service-subtitle="justTryBlockData.serviceSubtitle"
            :button-text="justTryBlockData.buttonText"
            :img-num="justTryBlockData.imgNum"
            :button-link-to-geoeffect="true"
        />

        <ArticlesSection />

        <QuestionSection />

        <FaqSection />

        <DocumentsSection />

        <CookiesWarning />
    </Layout>
</template>

<style lang="scss">
    @import '@design/components/header.scss';
    @import '@design/components/full-site-footer.scss';
    @import '@design/landing.scss';
</style>

